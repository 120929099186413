@import 'webresources-sources/scss/imports';
@import '../../../includes/components/slick-carousel-counter';

.gallery-component {
  .text-section {
    margin-bottom: 24px;
  }

  .carousel {
    &.slick-dotted.slick-slider {
      margin-bottom: 16px;
    }

    .slick-list {
      padding-bottom: 0 !important;
      border-bottom: none;
    }
  }

  .slick-slide {
    transition: opacity 500ms linear;

    img {
      transition: all 500ms linear;
    }

    .caption,
    .copyright {
      transition: opacity 500ms linear;
    }
  }

  .slick-slide:not(.slick-current) {
    opacity: 0.5;

    .caption,
    .copyright {
      opacity: 0;
    }

    @include media-breakpoint-up(md) {
      opacity: initial;
    }
  }

  &.portrait-variant {
    .slick-slide:not(.slick-current) {
      @include media-breakpoint-up(md) {
        opacity: 0;
      }
    }
  }

  &.cards-variant {
    .slick-list {
      margin-left: -20px;
      margin-right: -20px;
      max-width: 100vw;

      @include media-breakpoint-up(lg) {
        margin-left: initial;
        margin-right: initial;
        max-width: initial;
      }
    }

    .slick-slide {
      img {
        transform: translateX(160px) translateY(-16px) rotate(5deg) scale(0.85);
        z-index: -1;
        position: relative;
      }
    }

    .slick-slide.slick-center,
    .slick-slide.slick-current {
      z-index: 1;
      position: relative;

      img {
        transform: translateX(0) translateY(0) rotate(0deg) scale(1);
      }
    }

    .slick-slide.slick-center + .slick-slide,
    .slick-slide.slick-current + .slick-slide {
      img {
        transform: translateX(-160px) translateY(-16px) rotate(-5deg) scale(0.85);
      }
    }

    .slick-slide:not(.slick-cloned):not(.slick-current) ~ .slick-cloned {
      img {
        transform: translateX(-160px) translateY(-16px) rotate(-5deg) scale(0.85);
      }
    }

    .slick-slide:not(.slick-cloned):not(.slick-current) + .slick-cloned {
      img {
        transform: translateX(0) translateY(0) rotate(0deg) scale(1);
      }
    }

    .slick-slide:not(.slick-current) {
      opacity: 0.5;

      .caption,
      .copyright {
        opacity: 0;
      }
    }
  }
}
