@import 'webresources-sources/scss/imports';

.slider-actions {
  slick-carousel-counter {
    --current-slide-color: #{$color-darkblue};
    --separator-color: #{$color-darkgray-700};
    --slide-count-color: #{$color-darkgray-700};
  }
}

.bg-dark {
  .slider-actions {
    slick-carousel-counter {
      --current-slide-color: #{$color-white};
      --separator-color: #{$color-bluegray-400};
      --slide-count-color: #{$color-bluegray-400};
    }
  }
}
