/* bootstrap overwrite */
/* bootstrap overwrite */
.slider-actions slick-carousel-counter {
  --current-slide-color: #001f47;
  --separator-color: #546983;
  --slide-count-color: #546983;
}

.bg-dark .slider-actions slick-carousel-counter {
  --current-slide-color: #fff;
  --separator-color: #e4eaf7;
  --slide-count-color: #e4eaf7;
}

.gallery-component .text-section {
  margin-bottom: 24px;
}
.gallery-component .carousel.slick-dotted.slick-slider {
  margin-bottom: 16px;
}
.gallery-component .carousel .slick-list {
  padding-bottom: 0 !important;
  border-bottom: none;
}
.gallery-component .slick-slide {
  transition: opacity 500ms linear;
}
.gallery-component .slick-slide img {
  transition: all 500ms linear;
}
.gallery-component .slick-slide .caption,
.gallery-component .slick-slide .copyright {
  transition: opacity 500ms linear;
}
.gallery-component .slick-slide:not(.slick-current) {
  opacity: 0.5;
}
.gallery-component .slick-slide:not(.slick-current) .caption,
.gallery-component .slick-slide:not(.slick-current) .copyright {
  opacity: 0;
}
@media (min-width: 768px) {
  .gallery-component .slick-slide:not(.slick-current) {
    opacity: initial;
  }
}
@media (min-width: 768px) {
  .gallery-component.portrait-variant .slick-slide:not(.slick-current) {
    opacity: 0;
  }
}
.gallery-component.cards-variant .slick-list {
  margin-left: -20px;
  margin-right: -20px;
  max-width: 100vw;
}
@media (min-width: 992px) {
  .gallery-component.cards-variant .slick-list {
    margin-left: initial;
    margin-right: initial;
    max-width: initial;
  }
}
.gallery-component.cards-variant .slick-slide img {
  transform: translateX(160px) translateY(-16px) rotate(5deg) scale(0.85);
  z-index: -1;
  position: relative;
}
.gallery-component.cards-variant .slick-slide.slick-center,
.gallery-component.cards-variant .slick-slide.slick-current {
  z-index: 1;
  position: relative;
}
.gallery-component.cards-variant .slick-slide.slick-center img,
.gallery-component.cards-variant .slick-slide.slick-current img {
  transform: translateX(0) translateY(0) rotate(0deg) scale(1);
}
.gallery-component.cards-variant .slick-slide.slick-center + .slick-slide img,
.gallery-component.cards-variant .slick-slide.slick-current + .slick-slide img {
  transform: translateX(-160px) translateY(-16px) rotate(-5deg) scale(0.85);
}
.gallery-component.cards-variant .slick-slide:not(.slick-cloned):not(.slick-current) ~ .slick-cloned img {
  transform: translateX(-160px) translateY(-16px) rotate(-5deg) scale(0.85);
}
.gallery-component.cards-variant .slick-slide:not(.slick-cloned):not(.slick-current) + .slick-cloned img {
  transform: translateX(0) translateY(0) rotate(0deg) scale(1);
}
.gallery-component.cards-variant .slick-slide:not(.slick-current) {
  opacity: 0.5;
}
.gallery-component.cards-variant .slick-slide:not(.slick-current) .caption,
.gallery-component.cards-variant .slick-slide:not(.slick-current) .copyright {
  opacity: 0;
}